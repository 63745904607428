<script setup>

import { computed, ref, reactive } from 'vue';
import { verifyField } from '@/Ship';
import { useProfileActions } from '@profile';
import { useAuthComponents } from '@auth';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const PasswordInput = useAuthComponents('PasswordInput');

const isComplex = ref(false);
const errors = reactive({
    first_name: null,
    last_name: null,
    //email: null,
    //phone: null,
    password: false,
});

const firstName = computed({
    get: () => profile.first_name,
    set: value => {
        profile.first_name = value;
        errors.first_name = verifyField(value, ['require', 'minLength:2']);
        return true;
    }
});

const lastName = computed({
    get: () => profile.last_name,
    set: value => {
        profile.last_name = value;
        errors.last_name = verifyField(value, ['require', 'minLength:2']);
        return true;
    }
});

// const email = computed(() => {
//     return profile.email.replace(/^(.+)@(.+)$/, (match, p1, p2) => {
//         return p1.replace(/\w/g, '*') + '@' + p2;
//     });
// });

const email = computed({
    get: () => profile.email.replace(/^(.+)@(.+)$/, (match, p1, p2) => {
        return p1.replace(/\w/g, '*') + '@' + p2;
    }),
    set: value => {
        profile.email = value;
        errors.email = verifyField(value, ['require', 'email']);
        return true;
    }
});

// const phone = computed({
//     get: () => profile.phone,
//     set: value => {
//         profile.phone = value;
//         errors.phone = verifyField(value, ['require', 'phone']);
//         return true;
//     }
// });

const password = computed({
    get: () => profile.password,
    set: value => {
        profile.password = value;
        errors.password = verifyField(value, ['minLength:8']);
        return true;
    }
});

const updateProfile = () => {
    if (profile.isValidationPassed()) {
        profile.save();
        profile.clearEntity();
    }
};

const isAllow = computed(() => {
    for (const key in errors) {
        if (errors[key] !== null) {
            return false;
        }
    }

    return true;
});
</script>

<template>
    <form class="profile-form" v-on:submit.prevent="updateProfile" v-on:input="isAllow = true">

        <g-caption weight="600" size="2">{{ $t('parts.profile', 'Profile') }}</g-caption>

        <g-field v-model="firstName"
                 v-bind:label="$t('profile.first_name', 'First name')"
                 v-bind:error="errors.first_name || ''"/>

        <g-field type="text"
                 v-bind:label="$t('profile.last_name', 'Last name')"
                 v-model="lastName"
                 v-bind:error="errors.last_name || ''"/>

        <g-field v-model="email"
                 type="email"
                 label="Email"
                 v-bind:error="errors.email || ''"
                 readonly>
            <g-symbol name="lock" width="18" height="18"/>
        </g-field>

        <!--
        <g-phone-input v-if="countries.state.length"
                       v-model="phone"
                       v-model:country="profile.country_code"
                       v-bind:countries="countries.state"
                       v-bind:label="$t('profile.phone')"
                       v-bind:error="errors.phone || ''"/>
        -->

        <!--
        <g-field v-model="oldPassword"
                v-bind:label="$t('profile.old_password')"
                v-bind:type="showOldPassword ? 'text' : 'password'"
                autocomplete="new-password">
            <g-symbol v-bind:name="showOldPassword ? 'eye-open' : 'eye-close'"
                      v-on:click="showOldPassword = !showOldPassword"
                      width="20"
                      height="20"/>
            </g-field>
        -->

        <password-input v-model="password"
                        v-bind:complex="isComplex"
                        v-bind:label="$t('profile.new_password', 'New password')"
                        v-bind:error="errors.password || ''"/>

        {{ $t('auth.reset_sub_title_password') }}

        <div class="btn-bar" justify="end">
            <g-button class="save-btn"
                      type="submit"
                      v-bind:disabled="!isAllow">
                {{ $t('base.save', 'Save') }}
            </g-button>
        </div>
    </form>
</template>

<style lang="scss" scoped>
.profile-form {
    background-color: rgb($bar-bg, 0.5);
    border: 1px solid var(--separator-color, $separator-color);

    .g-field,
    .password-input,
    .g-phone-input {
        margin-top: 16px;
    }
}

.g-caption {
    color: var(--title-text-color, $title-text-color);
}

.password-set {
    margin-top: 25px;
    border-top: 1px solid var(--field-border, $field-border);
}

.btn-bar {
    margin-top: 20px;
}

.save-btn {
    width: 100%;
    padding: 0 20px;
    color: white;
    background-color: var(--apply-btn, $apply-btn);

    &.disabled {
        opacity: 0.4;
        filter: grayscale(1);
        pointer-events: none;
    }
}
</style>
