<template>
    <div class="send-detail">
        <ul class="detail-list">
            <li>
                <span class="title">
                    <!--<g-symbol name="chart-bar" width="24" height="24"/>-->
                    <g-symbol-currency width="22" height="22" v-bind:name="payload.currency.toLowerCase()"/>
                    {{ $t('wallet.currency', 'Currency') }}
                </span>
                <span class="value">{{ payload.currency }}</span>
            </li>
            <li>
                <div class="title">
                    <g-symbol name="card-send" width="24" height="24"/>
                    {{ $t('wallet.from_address', 'From Address') }}
                </div>
                <div class="value">{{ payload.address }}</div>
            </li>
            <li>
                <div class="title">
                    <g-symbol name="card-receive" width="24" height="24"/>
                    {{ $t('wallet.to_address', 'To Address') }}
                </div>
                <div class="value">{{ payload.toAddress }}</div>
            </li>
            <li>
                <div class="title">
                    <g-symbol name="wallet" width="24" height="24"/>
                    {{ $t('wallet.amount', 'Amount') }}
                </div>
                <div class="value">{{payload.amount}} {{ payload.currency }}</div>
            </li>
        </ul>
        <g-button type="buttno" class="approve-btn" v-on:click="sendTransaction">{{ $t('wallet.approve', 'Approve') }}</g-button>
    </div>
</template>

<script>
import { PopUpManager as PopUp } from '@/Ship';
import { useWalletResources } from '@wallet';

export default {
    name: 'SendDetail',
    emits: ['performed'],
    props: {
        payload: {
            type: Object
        },
        wallet: {
            type: Object
        }
    },
    setup(props, {emit}) {
        function sendTransaction() {
            const transactionPayload = {
                wallet_id: props.payload.walletId,
                address: props.payload.toAddress,
                amount: props.payload.amount,
                //fee: props.payload.fee
            };

            const transaction = useWalletResources('Transactions');
            transaction.create(transactionPayload);
            emit('performed');
        }

        return {
            PopUp,
            sendTransaction
        };
    }
};
</script>

<style lang="scss" scoped>
.detail-list {
    list-style: none;
    padding-top: 24px;
    width: 500px;
    max-width: 100%;
    container-type: inline-size;

    &:first-child {
        padding-top: 0;
    }

    li {
        margin: 15px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    .title {
        font-weight: 400;
        color: var(--disabled-color, $disabled-color);
        display: flex;
        align-items: center;

        & > .g-symbol {
            fill: var(--disabled-color, $disabled-color);
        }
    }

    .value {
        font-weight: 400;
        font-size: var(--text-sm, $text-sm);
        line-height: 140%;
        color: var(--main-text-color, $main-text-color);
        overflow: hidden;
        text-overflow: ellipsis;

        &.total {
            color: var(--primary, $primary);
            font-weight: 600;
        }
    }
}

.approve-btn {
    width: 100%;
    margin-top: 15px;
    color: white;
    background-color: var(--apply-btn, $apply-btn);

    &:hover {
        background-color: var(--apply-btn-hover, $apply-btn-hover);
    }
}

@container (min-width: 480px) {
    .g-symbol,
    .g-symbol-currency {
        margin-right: 10px;
    }
}

@container (max-width: 480px) {
    .g-symbol,
    .g-symbol-currency {
        margin-right: 5px;
    }
}
</style>