
import { useShipActions } from '@/Ship';

const probability = useShipActions('probability');

export default function(min, max, precision) {
    precision = precision || Number(min).toString().split('.')[1]?.length || 2;

    min = Number(min);

    const price = Number((Math.random() * (max - min) + min)).toFixed(precision < 2 ? 2 : precision);

    const amount = probability(20)
        ? Math.floor(Math.random() * (50 - 1) + 1) * 100
        : Math.floor(Math.random() * (10 - 1) + 1) * 100;

    return {
        price,
        amount,
        total: precision > 3
            ? Math.floor(amount / price)
            : Number((amount / price).toFixed(5))
    };
}