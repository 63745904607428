<template>
    <div class="deposit wrapper">
        <aside class="leftbar tabbar">
            <router-link class="currency"
                         v-bind:class="{ active: currency === 'btc' }"
                         v-bind:to="{name: 'Deposit', params: { ...$route.params, currency: 'btc' }}">
                <g-symbol-currency name="btc"/>
                <div class="symbol">BTC</div>
                <div class="currency-name">Bitcoin</div>
            </router-link>
            <router-link class="currency"
                         v-bind:class="{ active: currency === 'eth' }"
                         v-bind:to="{name: 'Deposit', params: { ...$route.params, currency: 'eth' }}">
                <g-symbol-currency name="eth"/>
                <div class="symbol">ETH</div>
                <div class="currency-name">Ethereum</div>
            </router-link>
            <router-link class="currency"
                         v-bind:class="{ active: currency === 'trx' }"
                         v-bind:to="{name: 'Deposit', params: { ...$route.params, currency: 'trx' }}">
                <g-symbol-currency name="trx"/>
                <div class="symbol">TRX</div>
                <div class="currency-name">Tron</div>
            </router-link>
            <router-link class="currency"
                         v-bind:class="{ active: currency === 'erc-20' }"
                         v-bind:to="{name: 'Deposit', params: { ...$route.params, currency: 'erc-20' }}">
                <g-symbol-currency name="usdt"/>
                <div class="symbol">USDT</div>
                <div class="currency-name">Tether (ERC-20)</div>
            </router-link>
            <router-link class="currency"
                         v-bind:class="{ active: currency === 'trc-20' }"
                         v-bind:to="{name: 'Deposit', params: { ...$route.params, currency: 'trc-20' }}">
                <g-symbol-currency name="usdt"/>
                <div class="symbol">USDT</div>
                <div class="currency-name">Tether (TRC-20)</div>
            </router-link>
        </aside>

        <main :class="STATE.IS_LIGHT_MODE? 'light-bg' : 'dark-bg'">
            <router-link v-bind:to="{ name: 'Trading', params: { locale: $i18n.locale } }" tag="div" class="close">
                <g-symbol name="close" width="24" height="24"/>
            </router-link>
            <div class="history-container">
                <history/>
            </div>
        </main>
    </div>
</template>

<script>

import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useDepositComponents } from '@deposit';
import { STATE } from '@/Ship';

export default {
    name: 'View',

    components: {
        ...useDepositComponents()
    },

    setup() {
        const route = useRoute();
        const currency = computed(() => {
            return route.params.currency || 'btc';
        });

        return {
            currency,
            STATE
        };
    }
};
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    align-items: stretch;
    background-color: var(--bar-bg, $bar-bg);
}

.leftbar {
    height: 100%;
    background-color: var(--bar-bg, $bar-bg);
    border-right: 1px solid var(--separator-color, $separator-color);

    & > .currency {
        font-size: 15px;
        line-height: 1.2;

        &:not(.active) {
            color: var(--disabled-color, $disabled-color);
            filter: grayscale(0.9);
        }

        &:hover {
            filter: grayscale(0.4);
            color: var(--main-text-color, $main-text-color);
        }

        &.active {
            filter: grayscale(0);
            color: var(--main-text-color, $main-text-color);
        }
    }
}

.light-bg {
    background-color: rgba($cover-color, 0.6);
    backdrop-filter: blur(10px);
    background-size: cover;
}

.close svg {
    position: absolute;
    top: 20px;
    right: 20px;
    fill: $primary;
    cursor: pointer;
}

main {
    position: relative;
    flex-grow: 1;
}

@media (min-width: 1445px) {
    .history-container {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        padding-top: 250px;
    }
}

@media (max-width: 1444px) {
    main {
        justify-content: space-between;
    }
}

@media (min-width: 985px) {
    main {
        display: flex;
    }
}

@media (max-width: 984px) {
    .leftbar {
        max-width: 180px;
    }

    main {
        overflow-y: auto;
    }

    .history-container {
        & > .history {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }
}

@media (min-width: 645px) {
    .leftbar {
        width: 280px;
        padding: 20px 0;

        & > .currency {
            height: 42px;
            margin: 30px 0;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;

            & > div {
                width: 100%;
                height: 50%;

                &.symbol {
                    font-weight: bold;
                }
            }

            & > .g-symbol-currency {
                width: 42px;
                height: 42px;
                margin-right: 8px;
            }
        }
    }
}

@media (max-width: 644px) {
    .leftbar {
        height: 100%;
        width: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .currency {
            width: 40px;
            margin: 15px 0;
            text-align: center;

            & > .g-symbol-currency {
                width: 32px;
                height: 32px;
            }

            & > .currency-name {
                display: none;
            }
        }
    }
}
</style>
