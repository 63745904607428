<script setup>

import { computed, defineProps } from 'vue';
import { i18n, PopUpManager as PopUp } from '@/Ship';
import { useWalletActions } from '@wallet';
import { useQuoteActions } from '@quotes';

const props = defineProps({
    index: {
        type: Number
    }
});

const getCryptoResource = useQuoteActions('getCryptoResource');
const crypto = getCryptoResource();

const {
    getCoinResource,
    getWalletResource
} = useWalletActions();

const coins = getCoinResource();
const coin = computed(() => {
    return coins.state[props.index]
});

const wallets = getWalletResource();

const walletIndex = wallets.state.findIndex((item) => item.coin.symbol === coin.value.symbol && item.coin.network === coin.value.network);
//const wallet = wallets.item(walletIndex);

const wallet = computed(() => {
    const walletIndex = wallets.state.findIndex((item) => item.coin.symbol === coin.value.symbol && item.coin.network === coin.value.network);

    return walletIndex > -1
        ? wallets.state[walletIndex]
        : null;
})

const balance = computed(() => {
    return Number(wallet.value.balance - wallet.value.hold_balance);
});

const rate = computed(() => {
    const currency = coin.value.symbol;
    return (currency !== 'USDT')
        ? crypto.find(currency, 'USDT')?.price
        : 1;
});

const openSendPopUp = () => {
    wallets.index = walletIndex;
    PopUp.open(require('@wallet/components/PopUps/Withdraw.vue')).setCaption(i18n.global.t('wallet.send')).setPayload({ wallet });
};

function createWallet() {
    //const coinId = coin.state.find((item) => item.symbol === wallet.value.symbol && item.network === wallet.value.coin.network).id;
    wallets.create({ coin_id: coin.value.id });
}
</script>

<template>
    <div class="wallet activated" v-bind:class="{ activated: !!wallet }">

        <!-- CARD -->
        <!--
        <wallet-card v-bind:network="network"
                     v-bind:symbol="currency"
                     v-bind:balance="balance"
                     v-bind:class="{disabled: !wallet}"/>
        -->
        <!-- /CARD -->

        <div class="cell symbol" data-title="Coin">
            <g-flex class="coin" direction="column" gap="6" inline wrap>
                <g-symbol-currency v-bind:name="coin.symbol.toLowerCase()"/>
                <span class="network">{{ coin.symbol }}</span>
                <span class="name">{{ coin.name }}</span>
            </g-flex>
        </div>

        <template v-if="wallet">
            <!--
            <div class="address">
                <g-caption size="4" weight="700">{{ $t('wallet.address') }}</g-caption>
                <g-copy v-bind:text="wallet.address"/>
            </div>
            -->
            <!--<replenishing v-bind:wallet="wallet"/>-->

            <div class="cell balance" data-title="Balance">
                <label>{{ $t('wallet.balance', 'Balance') }}:</label>
                {{ balance }} <span v-if="rate !== 1">/ ${{ Number((balance * rate).toFixed(2)) }}</span>
            </div>

            <div class="cell rate" data-title="Rate">
                <template v-if="rate !== 1">
                    <label>{{ $t('wallet.rate', 'Rate') }}:</label>
                    ${{ rate }}
                </template>
            </div>

            <!-- ACTIONS -->
            <div class="cell actions">
                <g-button class="replenish-btn" v-on:click="PopUp.open(require('@wallet/components/PopUps/Replenishing.vue')).setCaption($t('wallet.replenishing', 'Replenishing')).setPayload({wallet})">
                    {{ $t('wallet.replenishing', 'Withdrawal') }}
                    <g-symbol name="replenish" width="15" height="15"/>
                </g-button>
                <g-button class="receive-btn" v-on:click="PopUp.open(require('@wallet/components/PopUps/Receive.vue')).setCaption($t('wallet.receive', 'Receive')).setPayload({ address: wallet.address, symbol: wallet.coin.symbol })">
                    {{ $t('wallet.receive', 'Receive') }}
                    <g-symbol name="card-receive" width="18" height="18"/>
                </g-button>
                <g-button class="withdraw-btn" v-on:click="openSendPopUp">
                    {{ $t('wallet.withdrawal', 'Withdrawal') }}
                    <g-symbol name="card-send" width="18" height="18"/>
                </g-button>
                <!--
                <g-button class="remove-btn"
                          @click="PopUp.open(require('@wallet/components/parts/RemoveWallet')).setCaption($t('popupTitle.alert')).setPayload({ wallet: wallet })">
                    <g-symbol name="delete" width="22" height="22"/>
                </g-button>
                -->
            </div>
            <!-- /ACTIONS -->
        </template>

        <div v-else class="cell actions">
            <g-button class="activate-btn" v-on:click="createWallet">
                {{ $t('wallet.activate', 'Activate') }}
            </g-button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.wallet {
    display: grid;
    padding: 10px;
    align-items: center;
    border-bottom: 1px solid var(--separator-color, $separator-color);

    & > .symbol {
        grid-area: sym;
    }

    & > .balance {
        grid-area: bln;
    }

    & > .rate {
        grid-area: rat;
    }

    & > .actions {
        grid-area: act;

        & > .g-button {
            fill: white;
            color: white;

            &.replenish-btn {
                background-color: var(--secondary, $secondary);

                &:hover {
                    background-color: var(--secondary-hover, $secondary-hover);
                }
            }

            &.receive-btn,
            &.activate-btn {
                background-color: var(--success, $success);

                &:hover {
                    background-color: var(--success-hover, $success-hover);
                }
            }

            &.withdraw-btn {
                background-color: var(--danger, $danger);

                &:hover {
                    background-color: var(--danger-hover, $danger-hover);
                }
            }
        }
    }

    .coin {
        line-height: 0.8;
        align-content: start;

        & > .network {
            font-weight: 600;
            color: var(--title-text-color, $title-text-color);
            width: calc(100% - 46px);
        }

        & > .name {
            padding-bottom: 2px;
            font-size: 1rem;
            font-weight: 400;
            color: var(--main-text-color, $main-text-color);
        }
    }
}

.cell {
    padding: 0 10px;
    font-weight: 600;
    color: var(--title-text-color, $title-text-color);
    white-space: nowrap;

    & > label {
        display: block;
        font-weight: 400;
        color: var(--main-text-color, $main-text-color);
    }
}

@media (min-width: 885px) {
    .wallet {
        &.activated {
            grid-template-areas: 'sym bln rat act';
            grid-template-columns: 1fr 1fr 1fr auto;
        }

        &:not(.activated) {
            grid-template-areas: 'sym act';
            grid-template-columns: 240px auto;
        }
    }

    .coin {
        width: 168px;
        height: 48px;
        font-size: 1.2rem;

        & > .g-symbol-currency {
            width: 48px;
            height: 48px;
        }

        & > .network {
            font-size: 1.6rem;
            padding-top: 5px;
        }
    }

    .actions {
        white-space: nowrap;
        text-align: right;

        & > .g-button {
            padding: 0 20px;
            margin: 0 2px;
        }
    }

    @media (max-width: 924px) {
        .wallet {
            &.activated {
                grid-template-columns: 1fr 1.2fr 0.8fr auto;
            }
        }
    }
}

@media (max-width: 884px) {
    .wallet {
        padding: 20px 10px;
        font-size: 0.9rem;

        &:not(.activated) {
            grid-template-areas: 'sym act';
            grid-template-columns: auto 232px;
        }
    }

    .coin {
        height: 36px;
        font-size: 0.9rem;

        & > .g-symbol-currency {
            width: 36px;
            height: 36px;
        }

        & > .network {
            font-size: 1.1rem;
            padding-top: 4px;
        }
    }

    .actions {
        padding: 0 4px;
        display: flex;
        flex-wrap: wrap;
        gap: 4px;

        & > .g-button {
            padding: 0 9px;
            flex-grow: 1;

            &.replenish-btn {
                width: 100%;
            }
        }
    }

    @media (min-width: 625px) {
        .wallet {
            &.activated {
                grid-template-areas: 'sym bln rat act';
                grid-template-columns: 150px 1fr 1fr 232px;
            }
        }
    }

    @media (min-width: 445px) AND (max-width: 624px) {
        .wallet {
            grid-template-areas:
                'sym sym act'
                'bln rat act' !important;
            grid-template-columns: 150px 1fr minmax(150px, 1fr) !important;
        }

        .g-button {
            width: 100%;
        }
    }

    @media (max-width: 444px) {
        .wallet {
            grid-template-areas:
                'sym act'
                'bln act' !important;
            grid-template-columns: 160px auto !important;
        }

        .rate {
            display: none;
        }

        .cell {
            font-size: 1.1rem;
        }
    }
}
</style>
