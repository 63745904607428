<script setup>

import { computed, defineProps } from 'vue';

const props = defineProps(['address', 'symbol']);

const qrCodeLink = computed(() => {
    //const qrCodeGeneratorService = 'https://chart.googleapis.com/chart?cht=qr&chs=484x484&chl=';
    const qrCodeGeneratorService = 'https://qrcode.tec-it.com/API/QRCode?data=';

    return qrCodeGeneratorService.concat(props.address);
});
</script>

<template>
    <div class="receive">
        <img v-bind:src="qrCodeLink" v-bind:alt="address" width="450" height="450">
        <div class="symbol">{{ symbol }}</div>
        <g-copy v-bind:text="address" class="address"/>
    </div>
</template>

<style scoped lang="scss">
.receive {
    display: flex;
    flex-direction: column;
    align-items: center;
}

img {
    width: 100%;
    max-width: 300px;
    height: auto;
    background-color: white;
    border-radius: var(--bar-rounding, $bar-rounding);
}

.symbol {
    padding: 10px;
    font-weight: 600;
    font-size: 1.6rem;
    color: var(--title-text-color, $title-text-color);
}

.address {
    width: 100%;
    max-width: 320px;
    font-size: 13px;
    padding: 6px 10px;
    background-color: var(--field-bg, $field-bg);
    border: 1px solid var(--field-border, $field-border);
    border-radius: var(--field-rounding, $field-rounding);
}

@media (min-width: 665px) {
    img {
        padding: 30px;
    }
}

@media (max-width: 664px) {
    img {
        padding: 15px;
    }
}
</style>