<script setup>

import { ref, defineProps } from 'vue';
import { useShipActions } from '@/Ship';

const props = defineProps({
    text: {
        type: String,
        require: true
    }
});

const isShowTooltip = ref(false);
const copyText = useShipActions('copyText');

const onCopyClick = () => {
    isShowTooltip.value = true;
    copyText(props.text).then(() => {
        isShowTooltip.value = false;
    });
};
</script>

<template>
    <div class="g-copy" v-bind:class="{copied: isShowTooltip}">
        <span class="ellipsis">{{ text }}</span>
        <g-symbol name="copy" width="14" height="14" v-on:click="onCopyClick()"/>
    </div>
</template>

<style lang="scss" scoped>
.g-copy {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;

    &::before {
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        content: 'Copied';
        position: absolute;
        left: 50%;
        z-index: 99;
        padding: 4px 10px;
        border-radius: 5px;
        transform: translateX(-50%);
        background-color: #9093ad;
        pointer-events: none;
        transition: opacity 0.5s, bottom 0.4s;
        box-shadow: 4px 4px 14px -6px #000000aa;
    }

    &::after {
        content: '';
        left: 50%;
        position: absolute;
        border: 6px solid transparent;
        border-top-color: #b0b3cd;
        transform: translateX(-50%);
        transition: opacity 0.5s, top 0.4s;
    }

    &:not(.copied) {
        &::before {
            opacity: 0;
            bottom: 0;
        }

        &::after {
            top: 100%;
            opacity: 0;
        }
    }

    &.copied {
        &::before {
            opacity: 1;
            bottom: 100%;
        }

        &::after {
            top: 0;
            opacity: 1;
        }
    }

    & > .g-symbol {
        flex-shrink: 0;
        cursor: pointer;
        margin-left: 10px;
        fill: var(--primary, $primary);
    }
}

.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}
</style>