<template>
    <div class="tickers">
        <markets v-model="marketName"/>
        <component v-bind:is="marketName"
                   v-bind:symbol="{base: ticker.base, counter: ticker.counter}"
                   v-bind:sortable="'price'"/>
    </div>
</template>

<script>

import { computed } from 'vue';
import { STATE } from '@/Ship';
import { useQuoteActions, useQuoteComponents } from '@quotes';

const {
    getCryptoResource,
    getStockResource,
    getForexResource,
    getCommodityResource,
} = useQuoteActions();

const {
    Crypto,
    Stocks,
    Forex,
    Commodities,
    Markets,
} = useQuoteComponents();

export default {
    name: 'View',
    components: {
        Crypto,
        Stocks,
        Forex,
        Commodities,
        Markets,
    },
    props: {
        marketName: {
            type: String,
            default: "crypto"
        }
    },

    setup() {
        const resources = {
            crypto: getCryptoResource,
            stocks: getStockResource,
            forex: getForexResource,
            commodities: getCommodityResource,
        };


        const ticker = computed(() => {
            const resource = resources[STATE.MARKET]();
            return resource.state[resource.index] || resource.model;
        });

        return {
            ticker,
        };
    }
};
</script>

<style lang="scss" scoped>
.tickers {
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 2px solid var(--separator-color, $separator-color);
    background-color: rgba($bar-bg, 0.5);
}

.markets {
    z-index: 2;
}

</style>