<template>
    <form v-on:submit.prevent="sendTransaction">

        <fieldset>
            <legend>{{ $t('wallet.from_address', 'From Address') }}</legend>
            <g-input :value="wallet.model.address" readonly/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('wallet.to_address', 'To Address') }}</legend>
            <g-input v-model="payload.toAddress" required/>
        </fieldset>

        <div class="last-row">

            <fieldset>
                <legend>{{ $t('wallet.amount', 'Amount') }}</legend>
                <g-input v-model="payload.amount"
                         v-bind:label="$t('wallet.amount', 'Amount')"
                         step="any"
                         type="number"
                         required>
                    <g-symbol-currency width="22" height="22" v-bind:name="wallet.model.coin.symbol.toLowerCase()"/>
                    <span class="max-btn btn" v-on:click="maxAmount">max</span>
                </g-input>
            </fieldset>

            <g-button v-bind:disabled="!isAllow">{{ $t('base.send', 'Send') }}</g-button>
        </div>
    </form>
</template>

<script>
import { reactive, computed } from 'vue';
import { i18n, PopUpManager as PopUp } from '@/Ship';
import { useWalletResources } from '@wallet';

export default {
    name: 'Withdraw',

    setup() {
        const wallet = useWalletResources('Wallet');
        const payload = reactive({
            toAddress: '',
            amount: '',
            fee: '',
        });
        const feeOptions = [
            { value: '0.02', label: 'Fee 1', id: 1 },
            { value: '0.01', label: 'Fee 2', id: 2 },
            { value: '0.005', label: 'Fee 3', id: 3 },
        ];
        const isAllow = computed(() => {
            return payload.toAddress && (payload.amount <= Number(wallet.model.balance) && payload.amount > 0);
        });

        function sendTransaction() {
            PopUp.open(require('@wallet/components/PopUps/SendDetail.vue'))
            .setCaption(i18n.global.t('wallet.send_details', 'Transaction details'))
            .setPayload({
                payload: {
                    ...payload,
                    walletId: wallet.model.id,
                    address: wallet.model.address,
                    currency: wallet.model.coin.symbol,
                    name: wallet.model.name,
                    type: wallet.model.type,
                },
            });
        }

        function maxAmount() {
            payload.amount = Number(wallet.model.balance);
        }

        return {
            wallet,
            payload,
            isAllow,
            feeOptions,
            sendTransaction,
            maxAmount,
        };
    },
};
</script>

<style lang="scss" scoped>
form {
    width: 380px;
    max-width: 100%;
    padding-bottom: 20px;
    container-type: inline-size;
}

fieldset {
    margin-top: 20px;

    & > legend {
        margin: 0 10px;
    }
}

.max-btn {
    width: 40px;
    cursor: pointer;
    font-size: 13px;
    border-radius: 6px;
    padding: 1px 8px;
    margin-right: 6px;
    background-color: var(--tab-active-bg, $tab-active-bg);
    color: var(--tab-active-text-color, $tab-active-text-color);
}

.g-button {
    padding: 0 40px;
    margin-top: 8px;
    color: white;
    background-color: var(--apply-btn, $apply-btn);

    &:hover {
        background-color: var(--apply-btn-hover, $apply-btn-hover);
    }
}

@container (min-width: 350px) {
    .last-row {
        gap: 10px;
        display: flex;
        align-items: end;
    }
}
</style>