<script setup>
import { defineProps, defineEmits } from 'vue';
import { useWalletActions } from '@wallet';
import { useProfileActions } from '@profile';

const emit = defineEmits(['close']);

const props = defineProps({
    wallet: {
        type: Object
    },
    amount: {
        type: Number
    },
});

const getWalletResource = useWalletActions('getWalletResource');
const wallet = getWalletResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const replenishing = () => {
    const params = {
        amount: props.amount
    };

    if (!profile.withdraw_funds) {
        params.not_withdrawal = 1;
    }

    wallet.replenishment(props.wallet.id, params).then(response => {
        const walletIndex = wallet.state.findIndex(item => item.id === props.wallet.id);
        if (walletIndex > -1) {
            Object.assign(wallet.state[walletIndex], response.data);
        }
    });
    emit('performed');
};
</script>

<template>
    <div class="confirm">
        {{ $t('wallet.popupFaqSend') }}?
        <div class="btn-bar">
            <g-button class="cancel-btn" v-on:click="$emit('close')">
                {{ $t('base.cancel') }}
            </g-button>
            <g-button class="apply-btn" v-on:click="replenishing">
                {{ $t('base.replenishing') }}
            </g-button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.confirm {
    width: 280px;
    text-align: center;
}

.btn-bar {
    gap: 4px;
    display: flex;
    justify-content: center;
    margin-top: 32px;

    & > .g-button {
        width: 50%;
        padding: 0 20px;

        &.cancel-btn {
            color: white;
            background-color: var(--disabled-color, $disabled-color);

            &:hover {
                background-color: var(--disabled-color, $disabled-color);
            }
        }

        &.apply-btn {
            color: white;
            background-color: var(--apply-btn, $apply-btn);

            &:hover {
                background-color: var(--apply-btn-hover, $apply-btn-hover);
            }
        }
    }
}
</style>
