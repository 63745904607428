
import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Newsfeed extends BaseResource {

	static #instance = null;

    endpoint = 'newsfeed';

	static getInstance() {
		if (!Newsfeed.#instance) {
			Newsfeed.#instance = new Newsfeed();
		}
		return Newsfeed.#instance;
	}
}
