<script setup>

import { computed, ref, defineProps } from 'vue';
import { useQuoteActions } from '@quotes';
import { useShipActions } from '@/Ship';
import { useProfileActions } from '@profile';
import { useWalletActions } from '@wallet';

const props = defineProps(['wallet']);

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getCryptoResource = useQuoteActions('getCryptoResource');
const crypto = getCryptoResource();

const getWalletResource = useWalletActions('getWalletResource');
const wallets = getWalletResource();

const minUSDTAmount = profile.withdraw_fund ? 200 : 50;

const onlyNumberInput = useShipActions('onlyNumberInput');

const balance = computed(() => {
    return props.wallet.balance - props.wallet.hold_balance;
});

const amount = ref(balance.value);
const error = ref(checkAmount(amount.value));

const amountModel = computed({
    get: () => amount.value,
    set: value => {
        error.value = checkAmount(value);
        amount.value = value;

        return true;
    }
});

function checkAmount(amount) {
    if (amount > balance.value) {
        return 'Amount cannot be greater than balance';
    } else if (amount <= 0) {
        return 'Amount must be greater than zero';
    }

    if (props.wallet.coin.symbol === 'BTC') {
        const CounterPrice = crypto.find('BTC', 'USDT').price;
        const minAmount = Number((minUSDTAmount / CounterPrice).toFixed(4));
        if (amount < minAmount) {
            return `The minimum deposit amount in Bitcoin is ${minAmount} BTC`;
        }
    }

    return null;
}

const isShowMaxAmount = computed(() => {
    return (balance.value > 0) && amount.value < balance.value;
});

const replenishment = () => {
    const payload = {
        amount: amount.value
    };

    if (!profile.withdraw_funds) {
        payload.not_withdrawal = 1;
    }

    wallets.replenishment(props.wallet.id, payload).then(response => {
        const walletIndex = wallets.state.findIndex(item => item.id === props.wallet.id);
        if (walletIndex > -1) {
            Object.assign(wallets.state[walletIndex], response.data);
        }
    });

    //PopUp.open(require('@wallet/components/PopUps/ReplenishingConfirmation.vue'))
    //    .setCaption(i18n.global.t('wallet.confirm_replenish', 'Replenish Confirmatio'))
    //    .setPayload({wallet: props.wallet, amount: amount.value});
};
</script>

<template>
    <div class="replenishing">
        <!--
        <div class="title">
            {{ $t('wallet.trade_balance_replenishing', 'Replenishing the Trade Balance') }}
        </div>
        -->
        <g-caption size="4" weight="600" align="center">{{ wallet.coin.symbol }}</g-caption>
        <g-flex align="end" gap="5">
            <g-field v-model="amountModel"
                    v-bind:label="'Amount'"
                    v-on:keypress="onlyNumberInput($event)"
                    type="number"
                    v-bind:error="error || ''"
                    required>

                <g-symbol-currency v-bind:name="wallet.coin.symbol.toLowerCase()" width="20" height="20"/>

                <span v-if="isShowMaxAmount" class="max-btn" v-on:click="amount = balance">max</span>
                <!--<g-tooltip :tooltip-text="$t('label.maxAmount')" hover-tooltip></g-tooltip>-->
            </g-field>

            <g-button v-on:click="replenishment" v-bind:disabled="!!error">
                {{ $t('wallet.replenish', 'Replenish') }}
                <!--<strong>{{ exchangedValue }} USDT</strong>-->
            </g-button>
        </g-flex>
        <div class="available">
            {{ $t('base.available', 'Available') }}: <b>{{ balance }} {{ wallet.coin.symbol }}</b>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.replenishing {
    max-width: 380px;
    min-width: 260px;

    & > .title {
        font-size: 1.1rem;
        margin: -20px 0 20px 0;
        //color: var(--title-text-color, $title-text-color);
    }

    .g-caption {
        margin: -10px 0 10px 0;
        color: var(--title-text-color, $title-text-color);
    }

    .available {
        padding: 10px 0;

        & > b {
            color: var(--title-text-color, $title-text-color);
        }
    }

    .g-button {
        padding: 0 20px;
        color: white;
        background-color: var(--apply-btn, $apply-btn);

        &:hover {
            background-color: var(--success-hover, $success-hover);
        }
    }
}

.max-btn {
    width: 40px;
    cursor: pointer;
    font-size: 13px;
    border-radius: 6px;
    padding: 1px 8px;
    background-color: var(--tab-active-bg, $tab-active-bg);
    color: var(--tab-active-text-color, $tab-active-text-color);
}
</style>