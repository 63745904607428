<script setup>

import { computed, ref } from 'vue';

const languages = ref(
    process.env.VUE_APP_AVAILABLE_LOCALES.split('|')
);

const logo = computed(() => {
    let template = '';
    switch (true) {
        case /xxxxxx/.test(location.hostname):
            template = `<img src="${require('@assets/img/logo.svg')}" alt="LOGO"/>`;
            break;
        default:
            template = `<img src="${require('@assets/img/logo.svg')}" alt="LOGO"/>`;
            break;
    }

    return template;
});

</script>

<template>
    <div class="cover">

        <g-flex class="language" align="center" gap="5">
            <g-symbol name="globe" width="20" height="20"/>
            <g-lang-select v-bind:lang-list="languages"/>
        </g-flex>

        <div class="logo" v-html="logo"/>

        <slot name="form"/>
    </div>
</template>

<style lang="scss" scoped>
.cover {
    width: 100%;
    height: 100vh;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background:
        radial-gradient(100% 100% at 20% 20%, #f4c09320 0, transparent 40%),
        radial-gradient(100% 100% at 75% 30%, #4c6ccd20 0, transparent 45%),
        radial-gradient(100% 80% at center bottom, #6a07eb30 0, transparent 60%);
    background-color: var(--body-bg, $body-bg);

    &:deep(form) {
        margin: 0 10px;
        max-width: 460px;
        border-radius: 30px;
        background: radial-gradient(160% 140% at center bottom, #8a07eb20 0, transparent 60%);
        background-color: rgba($body-bg, 0.5);
        border: 2px solid rgba($separator-color, 0.8);
        box-shadow: 0 10px 40px -10px #00000033;

        & > .g-caption {
            width: 100%;
        }
    }
}

.logo {
    z-index: 1;
    top: 3%;
    left: 20px;
    position: absolute;
}

.language {
    top: 20px;
    right: 20px;
    position: fixed;
    fill: var(--main-text-color, $main-text-color);
}

@media (min-width: 425px) {
    :deep(form) {
        padding: 50px 40px;

        & > .g-caption {
            margin-top: -92px;
        }
    }
}

@media (max-width: 424px) {
    :deep(form) {
        padding: 30px 20px;

        & > .g-caption {
            margin-top: -72px;
        }
    }
}
</style>