<script setup>

import { useI18n } from 'vue-i18n';
import { computed, ref, defineEmits, defineProps } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const props = defineProps({
    langList: {
        required: true,
        type: Array,
    }
});

const emit = defineEmits(['selectedLang']);

const {
    locale,
    fallbackLocale: locales
} = useI18n();

//const language = ref(locale.value);
const show = ref(false);
const toTop = ref(false);
const datalist = ref(null);
const route = useRoute();
const router = useRouter();

const language = computed({
    get: () => locale.value,
    set: value => {
        router.push({
            name: route.name,
            params: {
                locale: value
            }
        });

        return true;
    }
});

const showList = () => {
    const { y } = datalist.value.getBoundingClientRect();
    toTop.value = (y + 200) > screen.availHeight;
    show.value = true;
};
const hideList = () => {
    setTimeout(() => {
        show.value = false;
    }, 100);
};
</script>

<template>
    <div class="g-lang-select lang-container" v-bind:class="{active: show}">
        <label>
            <input v-model="language"
                   v-on:focus="showList"
                   v-on:blur="hideList"
                   readonly>
            <g-symbol class="arrow" name="arrow-down" width="12" height="12"/>
        </label>

        <div ref="datalist"
             class="datalist"
             v-bind:class="{ show, 'to-top': toTop }">
            <label v-for="code in locales"
                   v-bind:key="code"
                   v-bind:class="{active: code === locale}">
                <input type="radio"
                       name="language"
                       v-model="language"
                       v-bind:value="code">
                {{ code }}
            </label>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.lang-container {
    z-index: 3;
    width: 56px;
    height: 30px;
    position: relative;
    border-radius: var(--dropdown-rounding, $dropdown-rounding);
    color: var(--dropdown-color, $dropdown-color);
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    & > label {
        cursor: pointer;

        & > input {
            height: 100%;
            border: none;
            width: calc(100% - 16px);
            font-size: 12px;
            padding-left: 6px;
            text-transform: uppercase;
            background-color: transparent;
            color: var(--main-text-color, $main-text-color);
        }

        & > .arrow {
            transition: transform 0.3s;
            fill: var(--main-text-color, $main-text-color);
        }
    }

    .datalist {
        left: 0;
        width: 100%;
        max-height: 0;
        position: absolute;
        overflow: hidden;
        box-shadow: 0 0 15px -5px #00000050;
        background-color: var(--dropdown-bg, $dropdown-bg);
        border-radius: var(--dropdown-rounding, $dropdown-rounding);
        transition: max-height 0.3s;

        &.to-top {
            bottom: 100%;
            margin-bottom: 2px;
        }

        &:not(.to-top) {
            top: 100%;
            margin-top: 2px;
        }

        label {
            display: block;
            cursor: pointer;
            padding: 6px 10px;
            transition: background-color 0.3s;
            color: var(--main-text-color, $main-text-color);

            &.active {
                display: none;
            }

            &:hover {
                color: white;
                background-color: var(--secondary, $secondary);
            }
        }
    }

    &.active {
        background-color: var(--env-bg, $env-bg);

        & > .datalist {
            max-height: 200px;
        }
    }

    & > label > input:focus {
        outline: none;

        & + .arrow {
            transform: rotate(-180deg);
        }
    }
}
</style>
