<script setup>

import { ref, computed } from 'vue';
import { usePositionComponents } from '@position';

const {
    Positions,
    History,
} = usePositionComponents();

const tab = ref('Positions');
const isTab = computed(() => {
    return {
        Positions,
        History
    }[tab.value];
});
</script>

<template>
    <div class="trading">
        <g-tabs v-model="tab" v-bind:tab-list="{Positions: $t('trading.positions','Positions'), History: $t('trading.history','History')}"/>

        <component v-bind:is="isTab"/>
    </div>
</template>

<style scoped lang="scss">
.g-tabs {
    background-color: var(--bar-bg, $bar-bg);
}

.trading {
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 2px solid var(--separator-color, $separator-color);
    background-color: rgba($bar-bg, 0.5);
}
</style>