<script setup>

import { computed } from 'vue';
import { useProfileActions } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');

const profile = getProfileResource();

const emailConfirmed = computed(() => {
    return profile.email_confirmed.toLowerCase() === 'yes';
});

const phoneConfirmed = computed(() => {
    return profile.phone_confirmed.toLowerCase() === 'yes';
});
</script>

<template>
    <g-flex class="infobar" justify="between" align="center">

        <g-flex class="id" inline align="center" gap="10">
            <g-symbol name="profile"/>
            <b>ID:</b>{{ profile.lead_id }}
        </g-flex>

        <!--
        <fieldset v-if="profile.account_level" class="level">
            <legend>Level:</legend>
            <g-caption size="2"  weight="bold" v-bind:class="profile.account_level.toLowerCase()">{{ profile.account_level }}</g-caption>
        </fieldset>
        -->

        <g-flex class="bar" justify="between" align="start" gap="10">
            <fieldset class="balance">
                <legend>Balance USD:</legend>
                {{ profile.balance }}
            </fieldset>

            <g-flex class="confirm-bar" gap="10" wrap style="flex-grow: 1">
            <fieldset class="email-confirm">
                <label>Email confirmed:</label>
                <!--<div v-bind:class="phoneConfirmed">{{ $t(`base.${emailConfirmed}`) }}</div>-->
                <g-symbol v-if="emailConfirmed" class="yes" name="check" width="20" height="20"/>
                <g-symbol v-else class="no" name="close" width="20" height="20"/>
            </fieldset>

            <fieldset class="phone-confirm">
                <label>Phone confirmed:</label>
                <!--<div v-bind:class="phoneConfirmed">{{ $t(`base.${phoneConfirmed}`) }}</div>-->
                <g-symbol v-if="phoneConfirmed" class="yes" name="check" width="20" height="20"/>
                <g-symbol v-else class="no" name="close" width="20" height="20"/>
            </fieldset>
            </g-flex>

            <fieldset class="date">
                <legend>Registration at:</legend>
                {{ profile.created_at }}
            </fieldset>
        </g-flex>
    </g-flex>
</template>

<style lang="scss" scoped>
.infobar {
    padding: 0 10px;
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 2px solid var(--separator-color, $separator-color);
    background-color: rgba($bar-bg, 0.5);
    container-type: inline-size;
}

.id {
    height: 100%;
    font-weight: 400;
    fill: var(--main-text-color, $main-text-color);
    color: var(--title-text-color, $title-text-color);
    border-right: 1px solid var(--separator-color, $separator-color);

    & > b {
        color: var(--main-text-color, $main-text-color);
    }
}

.bar {
    flex-grow: 1;
    flex-wrap: wrap;
}

fieldset {
    white-space: nowrap;

    label,
    legend {
        display: block;
        font-size: 0.9rem;
        font-weight: 400;
        color: var(--main-text-color, $main-text-color);
    }
}

.balance {
    font-weight: 600;
    color: var(--title-text-color, $title-text-color);
}

.date {
    color: var(--title-text-color, $title-text-color);
}

.email-confirm,
.phone-confirm {
    text-align: center;

    & > .g-symbol {
        fill: white;
        padding: 4px;
        border-radius: 50%;

        &.yes {
            background-color: var(--success, $success);
        }

        &.no {
            background-color: var(--danger, $danger);
        }
    }
}

@container (min-width: 645px) {
    .id {
        padding: 0 20px;
        font-size: 1.4rem;

        & > .g-symbol {
            width: 36px;
        }
    }

    .bar {
        padding: 0 20px;
        border: 1px solid transparent;
    }

    .balance {
        font-size: 1.5rem;
    }

    .confirm-bar {
        justify-content: space-evenly;
    }
}

@container (max-width: 644px) {
    .id {
        padding: 0 10px;
        font-size: 1.2rem;

        & > .g-symbol {
            width: 26px;
        }
    }

    .bar {
        padding: 10px 3.6vw;
        border: 1px solid transparent;
        flex-direction: column;
    }

    .balance {
        font-size: 1.2rem;
    }

    .confirm-bar {
        justify-content: start;
    }

    .email-confirm,
    .phone-confirm {
        gap: 10px;
        display: flex;
        justify-content: space-around;
    }
}
</style>
