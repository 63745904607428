
import { useNewsfeedResources } from '@newsfeed';

export default (reload = false) => {
    const newsfeed =  useNewsfeedResources('Newsfeed');

    if (reload || (!newsfeed.isLoaded && !newsfeed.isLoading)) {
        newsfeed.load();
    }

    return newsfeed
};
