<template>
    <currency-sprite/>

    <router-view/>
    <g-preloader-overlay v-if="STATE.IS_LOADING"/>
    <g-popup v-if="PopUp.component.value">
        <component v-bind:is="PopUp.component.value"
                   v-bind="PopUp.payload"
                   v-on:performed="PopUp.action"
                   v-on:close="PopUp.close()"/>
    </g-popup>

    <live-chat-widget
        v-if="STATE.IS_LOGIN"
        v-bind:license="liveChatLicense"
        v-bind:customer-name="customerName"
        v-bind:customer-email="customerEmail"
    />

</template>

<script>

import { computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import CurrencySprite from '@/Ship/components/currency-sprite';
import { PopUpManager as PopUp, STATE } from '@/Ship';
import { LiveChatWidget } from '@livechat/widget-vue';
import { useProfileResources } from '@profile';
import * as FullStoryModule from '@fullstory/browser'


export default {
    name: 'PTFX-APP',
    head: {
        link: [
            {
                rel: 'icon',
                href: require('@/Ship/assets/img/pfx.svg')
            },
        ]
    },
    components: {
        CurrencySprite,
        LiveChatWidget,
    },

    setup() {

        const router = useRouter();
        const profile = useProfileResources('Profile');

        watch(() => STATE.IS_LOGIN, () => {
            if (!STATE.IS_LOGIN) {
                router.push({ name: 'SignIn', params: router.currentRoute.value.params });
            }
        });
        

        const customerName = computed(() => `${profile.first_name || ""} ${profile.last_name || ""}`);
        const customerEmail = computed(() => profile.email);

        const fullstoryId = process.env.VUE_APP_FULLSTORY_ID;
        if (fullstoryId) {
            FullStoryModule.init({
                orgId: fullstoryId,
                //debug: true,
            });
        }

        return {
            PopUp,
            STATE,
            customerName,
            customerEmail,
            liveChatLicense: process.env.VUE_APP_LIVE_CHAT_LICENSE
        };
    },
};
</script>
<style lang="scss">
@import './assets/index';

.hide-sprite {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
}
</style>
